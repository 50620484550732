.form-wrapper{
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.form-wrapper .input-wrapper {
    border: 1px solid rgb(197, 197, 197);
    border-radius: 10px;
    padding: 30px 30px 20px 30px;
}
.form-wrapper .input-wrapper input {
    display: block;
    border: none;
    font-size: 18px;
    border-bottom: 1px solid rgb(197, 197, 197);
    padding-bottom: 5px;
    padding-left: 5px;
    min-width: 50% !important;
    outline: none !important;
    margin-bottom: 20px;
}
.form-wrapper .input-wrapper input[type="radio"] {
    min-width: unset !important;
    width: 23px !important;
    height: 23px;
}
.input-wrapper label {
    color: var(--purple);
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 30px;
}