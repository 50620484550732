.summary-wrapper{
    max-width: 520px;
}
.summary-wrapper input {
    outline : none;
}
.payment-option-header {
    padding: 8px ;
    background-color: #f7f9fa ;
}

.payment-option-body{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 15px;
}

.payment-option-body input {
    width: 100%;
    padding: 8px 12px;
    outline-offset: 4px;
}
.loader{
    animation: loading 1.5s infinite linear;
}
@keyframes loading {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

@media (max-width: 576px) {
    .payment-wrapper .section-heading p {
        font-size: 16px !important;
    }
}